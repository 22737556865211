.Login {
	height: 100%;
	background-color: #f7f8ff;

	.disable {
		opacity: 0.4;
	}

	.Login-title {
		padding-top: 50px;
		span {
			font-size: 36px;
			font-weight: 900;
			border-bottom: 3px solid $mdc-theme-primary;
		}

		text-align: center;
		color: $mdc-theme-primary;
	}
	.Login-grid {
		width: 100%;
		display: inline-block;
		text-align: center;
		position: absolute;
		top: 35%;

		.Login-cell {
			display: inline-block;
			vertical-align: middle;
			width: 200px;

			.Login-cell-title {
				height: 60px;
			}
			.Login-logo {
				min-height: 100px;
				vertical-align: middle;

				.Login-logo-icon {
					width: 100px;
				}

				.Login-logo-icon-oa {
					vertical-align: middle;
					width: 190px;
					margin-top: 25px;
				}
			}

			.Login-btn {
				width: 100px;
				background-color: $mdc-theme-primary;
				color: white;
				border-radius: 18px;
				box-shadow: 5px 10px 10px rgba(10, 81, 218, 0.2);
			}

			.Login-btn:hover {
				box-shadow: 5px 10px 10px rgba(10, 81, 218, 0.9);
			}
		}

		.Login-cell:hover {
			padding: 20px;
			box-shadow: 5px 10px 10px rgba(10, 81, 218, 0.2);
		}
	}
	@media (max-width: 400px) {
		.Login-grid {
			top: 20%;
		}
	}
	.Login-footer {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: $mdc-theme-primary;
		color: white;
		text-align: center;
		cursor: pointer;

		.Git-logo-icon {
			height: 36px;
			border-top: 12px;
		}

		.Login-footer-msg {
			line-height: 36px;
			color: white;
		}
	}

	.Login-footer:hover {
		background-color: black;
	}

	.Login-greeting {
		position: fixed;
		left: 20px;
		bottom: 40px;
		width: 100%;
		font-weight: 900;
		font-family: '华文细黑', 'Microsoft YaHei', '微软雅黑', serif;
	}
}
