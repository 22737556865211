.TargetList {
  margin-left: 15px;
}

.TargetList-item {
  border-bottom: 1px solid;
}

.TargetList-item .mdc-list-item__secondary-text {
  margin-top: 5px;
}
.TargetList-group-item{
  float: left;
  width: 95%;
}

.DeleteTarget-div {
  display: inline;
  float: right;
  text-align: right;
  width: auto;
  cursor: pointer;
}