.GroupDetail-grid {
  width: 100%;
}

.GroupDetail-inner {
  width: 100%;
}

.GroupDetail-cell {

}

.PersonDelete {
  cursor: pointer;
}

.GroupDetail-jon-link {
  width: 550px;
  color: cornflowerblue;
  overflow: auto;
}