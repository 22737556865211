.hidden { display:none; }

.TopAppBarRippleSpan {
    font-size: 0.8em;
    --mdc-ripple-fg-size: 28.8px !important;
    --mdc-ripple-fg-scale: 1.66667 !important;
    --mdc-ripple-left: 5px !important;
    --mdc-ripple-top: 10px !important;
    --mdc-ripple-fg-translate-start: 0px, 0px !important;
    --mdc-ripple-fg-translate-end: 0px, 0px !important;
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
    width: 180px !important;
}

.TopAppBar {
    top: 0 !important;
}
