@charset "UTF-8";
:root {
  --mdc-theme-primary: #0A51DA;
  --mdc-theme-secondary: #018786;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-error: #b00020;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-on-surface: #000;
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #0A51DA !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #0A51DA) !important;
}

.mdc-theme--secondary {
  color: #018786 !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020 !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important;
}

.mdc-theme--on-secondary {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important;
}

.mdc-theme--on-surface {
  color: #000 !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: white !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #0A51DA !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0A51DA) !important;
}

.mdc-theme--secondary-bg {
  background-color: #018786 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #018786) !important;
}

.mdc-card {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.mdc-card--outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #e0e0e0;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-card__primary-action::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-card__primary-action.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  background-color: #000;
}
.mdc-card__primary-action:hover::before {
  opacity: 0.04;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px;
}
[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-card__action--button:last-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  text-align: left;
}
[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  text-align: right;
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden;
}
.mdc-ripple-surface--test-edge-var-bug::before {
  border: var(--mdc-ripple-surface-test-edge-var);
}

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px;
}
.mdc-button::before, .mdc-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button::before, .mdc-button::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.37);
  cursor: default;
  pointer-events: none;
}
.mdc-button:not(:disabled) {
  background-color: transparent;
}
.mdc-button:not(:disabled) {
  color: #0A51DA;
  /* @alternate */
  color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-button::before, .mdc-button::after {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button::before, .mdc-button::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
.mdc-button:hover::before {
  opacity: 0.04;
}
.mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}
.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-button svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}
.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.37);
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-button--raised::before, .mdc-button--raised::after,
.mdc-button--unelevated::before,
.mdc-button--unelevated::after {
  background-color: #fff;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-primary, #fff);
  }
}
.mdc-button--raised:hover::before,
.mdc-button--unelevated:hover::before {
  opacity: 0.08;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-button--raised:not(.mdc-ripple-upgraded)::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.32;
}
.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.32;
}

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--raised:hover, .mdc-button--raised:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px;
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.37);
}
.mdc-button--outlined:not(:disabled) {
  border-color: #0A51DA;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0A51DA);
}

.mdc-button--dense {
  height: 32px;
  font-size: 0.8125rem;
}

.material-icons--ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.material-icons--ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.material-icons--ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.material-icons--ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.material-icons--ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.material-icons--ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.material-icons--ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.material-icons--ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
  background-color: #000;
}
.material-icons--ripple-surface:hover::before {
  opacity: 0.04;
}
.material-icons--ripple-surface:not(.mdc-ripple-upgraded):focus::before, .material-icons--ripple-surface.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.material-icons--ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.material-icons--ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.material-icons--ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden;
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  background-color: #000;
}
.mdc-ripple-surface:hover::before {
  opacity: 0.04;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded] {
  overflow: visible;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
.mdc-ripple-surface--primary:hover::before {
  opacity: 0.04;
}
.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--primary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-ripple-surface--primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}
.mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
  background-color: #018786;
}
@supports not (-ms-ime-align: auto) {
  .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #018786);
  }
}
.mdc-ripple-surface--accent:hover::before {
  opacity: 0.04;
}
.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--accent.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-ripple-surface--accent.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}

@keyframes mdc-select-float-native-control {
  0% {
    transform: translateY(8px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.mdc-line-ripple {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2;
}

.mdc-line-ripple--active {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating {
  opacity: 0;
}

.mdc-notched-outline,
.mdc-notched-outline__idle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-notched-outline {
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  text-align: left;
  overflow: hidden;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  text-align: right;
}
.mdc-notched-outline svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-notched-outline__idle {
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid;
  opacity: 1;
}

.mdc-notched-outline__path {
  stroke-width: 1px;
  transition: stroke 150ms cubic-bezier(0.4, 0, 0.2, 1), stroke-width 150ms cubic-bezier(0.4, 0, 0.2, 1);
  fill: transparent;
}

.mdc-notched-outline--notched {
  opacity: 1;
}

.mdc-notched-outline--notched ~ .mdc-notched-outline__idle {
  opacity: 0;
}

.mdc-floating-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  bottom: 8px;
  left: 0;
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform;
}
[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
  /* @noflip */
  transform-origin: right top;
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--float-above {
  transform: translateY(-100%) translateX(0%) scale(0.75);
}
[dir=rtl] .mdc-floating-label--float-above, .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-100%) translateX(0%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75);
  }
}
.mdc-select {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 52px;
  background-repeat: no-repeat;
  background-position: right 8px bottom 12px;
}
.mdc-select:not(.mdc-select--disabled) {
  background-color: transparent;
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-line-ripple {
  background-color: #0A51DA;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
  color: rgba(10, 81, 218, 0.87);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .mdc-select, .mdc-select[dir=rtl] {
  background-position: left 8px bottom 12px;
}
.mdc-select__native-control {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 26px;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
}
[dir=rtl] .mdc-select__native-control, .mdc-select__native-control[dir=rtl] {
  /* @noflip */
  padding-left: 26px;
  /* @noflip */
  padding-right: 0;
}
.mdc-select__native-control::-ms-expand {
  display: none;
}
.mdc-select__native-control::-ms-value {
  background-color: transparent;
  color: inherit;
}
@-moz-document url-prefix("") {
  .mdc-select__native-control {
    text-indent: -2px;
  }
}
.mdc-select__native-control > option {
  color: inherit;
}
.mdc-select .mdc-floating-label {
  pointer-events: none;
}
.mdc-select .mdc-select__native-control:focus ~ .mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}

.mdc-select--box {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  height: 56px;
  border-radius: 4px 4px 0 0;
  background-position: right 10px center;
  overflow: hidden;
}
.mdc-select--box:not(.mdc-select--disabled) {
  background-color: whitesmoke;
}
.mdc-select--box::before, .mdc-select--box::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-select--box::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-select--box.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select--box.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-select--box.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-select--box.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-select--box.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select--box::before, .mdc-select--box::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-select--box.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-select--box::before, .mdc-select--box::after {
  background-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--box:hover::before {
  opacity: 0.04;
}
.mdc-select--box:not(.mdc-ripple-upgraded):focus::before, .mdc-select--box.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
[dir=rtl] .mdc-select--box, .mdc-select--box[dir=rtl] {
  background-position: left 10px center;
}
.mdc-select--box .mdc-select__native-control {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 26px;
  height: 56px;
  border-radius: 4px 4px 0 0;
}
[dir=rtl] .mdc-select--box .mdc-select__native-control, .mdc-select--box .mdc-select__native-control[dir=rtl] {
  /* @noflip */
  padding-left: 26px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select--box .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  bottom: 12px;
  line-height: 1.75rem;
}
[dir=rtl] .mdc-select--box .mdc-floating-label, .mdc-select--box .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-select--box .mdc-floating-label--float-above {
  transform: translateY(-40%) scale(0.75, 0.75);
}

.mdc-select--outlined {
  height: 56px;
  border: none;
  background-position: right 10px center;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.24);
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.24);
}
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select__native-control:focus) .mdc-select__native-control:hover ~ .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select__native-control:focus) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.87);
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke-width: 2px;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: #0A51DA;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #0A51DA);
}
.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(0%) scale(0.75);
}
[dir=rtl] .mdc-select--outlined .mdc-floating-label--float-above, .mdc-select--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(0%) scale(0.75);
}
.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
.mdc-select--outlined .mdc-notched-outline {
  border-radius: 4px;
}
.mdc-select--outlined .mdc-notched-outline__idle {
  border-radius: 4px;
}
[dir=rtl] .mdc-select--outlined, .mdc-select--outlined[dir=rtl] {
  background-position: left 10px center;
}
.mdc-select--outlined .mdc-select__native-control {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 26px;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;
  background-color: transparent;
  z-index: 1;
}
[dir=rtl] .mdc-select--outlined .mdc-select__native-control, .mdc-select--outlined .mdc-select__native-control[dir=rtl] {
  /* @noflip */
  padding-left: 26px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select--outlined .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  position: absolute;
  bottom: 20px;
}
[dir=rtl] .mdc-select--outlined .mdc-floating-label, .mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-select--disabled {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23#000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  cursor: default;
  pointer-events: none;
}
.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-select--disabled .mdc-line-ripple {
  display: none;
}
.mdc-select--disabled .mdc-select__native-control {
  border-bottom-style: dotted;
  color: rgba(0, 0, 0, 0.37);
}
.mdc-select--disabled.mdc-select--box {
  background-color: #fafafa;
}
.mdc-select--disabled.mdc-select--outlined .mdc-select__native-control {
  border-bottom-style: none;
}
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.16);
}
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.16);
}

.mdc-top-app-bar {
  background-color: #0A51DA;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0A51DA);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #fff;
}
@supports not (-ms-ime-align: auto) {
  .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
  .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
  .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-primary, #fff);
  }
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before {
  opacity: 0.08;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.32;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.32;
}
.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}
.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}
.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}
.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}
.mdc-top-app-bar__title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}
.mdc-top-app-bar__action-item, .mdc-top-app-bar__navigation-icon {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  padding: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-top-app-bar__action-item::before, .mdc-top-app-bar__navigation-icon::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded--unbounded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-activation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-deactivation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-top-app-bar--short {
  top: 0;
  right: auto;
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  right: 0;
  left: auto;
}
.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-bottom-left-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /* @noflip */
  border-bottom-left-radius: 4px;
  /* @noflip */
  border-bottom-right-radius: 0;
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}
.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px;
}
[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }
  .mdc-top-app-bar__section {
    padding: 4px;
  }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 0;
  }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.material-icons--ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.material-icons--ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.material-icons--ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.material-icons--ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.material-icons--ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.material-icons--ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.material-icons--ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.material-icons--ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
  background-color: #000;
}
.material-icons--ripple-surface:hover::before {
  opacity: 0.04;
}
.material-icons--ripple-surface:not(.mdc-ripple-upgraded):focus::before, .material-icons--ripple-surface.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.material-icons--ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.material-icons--ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.material-icons--ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer;
}

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity;
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity;
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer;
}

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-text-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  will-change: opacity, transform, color;
}
.mdc-text-field::before, .mdc-text-field::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-text-field.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-text-field.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field::before, .mdc-text-field::after {
  background-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field:hover::before {
  opacity: 0.04;
}
.mdc-text-field:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-text-field::before, .mdc-text-field::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #0A51DA;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-line-ripple {
  background-color: #0A51DA;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  bottom: 20px;
}
[dir=rtl] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-floating-label {
  max-width: calc(100% - 48px);
}
.mdc-text-field .mdc-floating-label--float-above {
  transform: translateY(-50%) translateX(0%) scale(0.75);
}
[dir=rtl] .mdc-text-field .mdc-floating-label--float-above, .mdc-text-field .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-50%) translateX(0%) scale(0.75);
}
.mdc-text-field .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-box 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-box {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
  }
}
.mdc-text-field .mdc-floating-label {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
  pointer-events: none;
}
[dir=rtl] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

.mdc-text-field__input {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 12px 6px;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  appearance: none;
}
.mdc-text-field__input::placeholder {
  transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  transform: translateY(-50%) scale(0.75);
  cursor: auto;
}

.mdc-text-field--outlined {
  border: none;
  overflow: visible;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.24);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.24);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: #0A51DA;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(0%) scale(0.75);
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(0%) scale(0.75);
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
.mdc-text-field--outlined .mdc-notched-outline {
  border-radius: 4px;
}
.mdc-text-field--outlined .mdc-notched-outline__idle {
  border-radius: 4px;
}
.mdc-text-field--outlined::before, .mdc-text-field--outlined::after {
  content: none;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  padding: 12px 16px 14px;
  border: none !important;
  background-color: transparent;
  z-index: 1;
}
.mdc-text-field--outlined .mdc-text-field__icon {
  z-index: 2;
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  width: auto;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__path {
  stroke-width: 2px;
}

.mdc-text-field--outlined.mdc-text-field--disabled {
  background-color: transparent;
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom: none;
}

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-110%) translateX(0%) scale(0.923);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-110%) translateX(0%) scale(0.923);
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 7px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  bottom: 16px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
  top: 12px;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-110%) translateX(-21px) scale(0.923);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-110%) translateX(21px) scale(0.923);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1;
}

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 48px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 12px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8);
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 38px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 38px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 38px;
}

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 38px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 12px;
}

.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-70%) translateX(0%) scale(0.923);
}
[dir=rtl] .mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-70%) translateX(0%) scale(0.923);
}
.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1;
}
.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0;
}
.mdc-text-field--dense .mdc-floating-label {
  font-size: 0.813rem;
}

.mdc-text-field__input:required + .mdc-floating-label::after {
  margin-left: 1px;
  content: "*";
}

.mdc-text-field--textarea {
  border-radius: 4px;
  display: flex;
  width: auto;
  height: auto;
  transition: none;
  border: 1px solid;
  overflow: hidden;
}
.mdc-text-field--textarea .mdc-floating-label {
  border-radius: 4px 4px 0 0;
}
.mdc-text-field--textarea .mdc-text-field__input {
  border-radius: 2px;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  border-color: rgba(0, 0, 0, 0.73);
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
  border-color: rgba(0, 0, 0, 0.73);
}
.mdc-text-field--textarea .mdc-floating-label--float-above {
  transform: translateY(-50%) translateX(0%) scale(0.923);
}
[dir=rtl] .mdc-text-field--textarea .mdc-floating-label--float-above, .mdc-text-field--textarea .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-50%) translateX(0%) scale(0.923);
}
.mdc-text-field--textarea .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea 250ms 1;
}
.mdc-text-field--textarea::before, .mdc-text-field--textarea::after {
  content: none;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--textarea .mdc-text-field__input {
  align-self: auto;
  box-sizing: content-box;
  height: auto;
  margin: 0;
  padding: 16px;
  padding-top: 32px;
  border: 1px solid transparent;
}
.mdc-text-field--textarea .mdc-floating-label {
  background-color: white;
  /* @noflip */
  left: 1px;
  /* @noflip */
  right: 0;
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  top: 18px;
  bottom: auto;
  width: auto;
  margin-top: 2px;
  padding: 12px 8px 8px 8px;
  line-height: 1.15;
  pointer-events: none;
}
[dir=rtl] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: 1px;
}
[dir=rtl] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

.mdc-text-field--fullwidth {
  width: 100%;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  display: block;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::before, .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::after {
  content: none;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding: 0;
}

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #b00020;
}

.mdc-text-field--dense + .mdc-text-field-helper-text {
  margin-bottom: 4px;
}
.mdc-text-field + .mdc-text-field-helper-text {
  margin-right: 12px;
  margin-left: 12px;
}
.mdc-text-field--outlined + .mdc-text-field-helper-text {
  margin-right: 16px;
  margin-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(10, 81, 218, 0.87);
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(10, 81, 218, 0.87);
}
.mdc-text-field--focused .mdc-text-field__input:required + .mdc-floating-label::after {
  color: #b00020;
}
.mdc-text-field--focused + .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) {
  border-color: #0A51DA;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
  border-color: #0A51DA;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0A51DA);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
}
.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #b00020;
}
.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
  border-color: #b00020;
}
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
  border-color: #b00020;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
  border-color: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: #b00020;
}

.mdc-text-field--disabled {
  background-color: #fafafa;
  border-bottom: none;
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-text-field__input::placeholder {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled + .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-text-field__icon {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled:not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}

.mdc-text-field--textarea.mdc-text-field--disabled {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: rgb(249, 249, 249);
  border-bottom-width: 1px;
  border-style: solid;
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input:focus {
  border-color: rgba(0, 0, 0, 0.26);
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
  border: 1px solid transparent;
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-floating-label {
  background-color: rgb(249, 249, 249);
}

@keyframes mdc-floating-label-shake-float-above-text-field-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
  }
}
/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-elevation--z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}
.mdc-layout-grid__cell--order-2 {
  order: 2;
}
.mdc-layout-grid__cell--order-3 {
  order: 3;
}
.mdc-layout-grid__cell--order-4 {
  order: 4;
}
.mdc-layout-grid__cell--order-5 {
  order: 5;
}
.mdc-layout-grid__cell--order-6 {
  order: 6;
}
.mdc-layout-grid__cell--order-7 {
  order: 7;
}
.mdc-layout-grid__cell--order-8 {
  order: 8;
}
.mdc-layout-grid__cell--order-9 {
  order: 9;
}
.mdc-layout-grid__cell--order-10 {
  order: 10;
}
.mdc-layout-grid__cell--order-11 {
  order: 11;
}
.mdc-layout-grid__cell--order-12 {
  order: 12;
}
.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}
.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc(var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2);
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc(var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2);
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc(var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2);
  }
}

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px;
}
.mdc-button::before, .mdc-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button::before, .mdc-button::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.37);
  cursor: default;
  pointer-events: none;
}
.mdc-button:not(:disabled) {
  background-color: transparent;
}
.mdc-button:not(:disabled) {
  color: #0A51DA;
  /* @alternate */
  color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-button::before, .mdc-button::after {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button::before, .mdc-button::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
.mdc-button:hover::before {
  opacity: 0.04;
}
.mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}
.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-button svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}
.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.37);
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-button--raised::before, .mdc-button--raised::after,
.mdc-button--unelevated::before,
.mdc-button--unelevated::after {
  background-color: #fff;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-primary, #fff);
  }
}
.mdc-button--raised:hover::before,
.mdc-button--unelevated:hover::before {
  opacity: 0.08;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-button--raised:not(.mdc-ripple-upgraded)::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.32;
}
.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.32;
}

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--raised:hover, .mdc-button--raised:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px;
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.37);
}
.mdc-button--outlined:not(:disabled) {
  border-color: #0A51DA;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0A51DA);
}

.mdc-button--dense {
  height: 32px;
  font-size: 0.8125rem;
}

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  height: 32px;
  display: inline-flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  padding: 7px 12px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
}
.mdc-chip::before, .mdc-chip::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-chip::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-chip.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-chip.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-chip.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-chip.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip::before, .mdc-chip::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-chip.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-chip::before, .mdc-chip::after {
  background-color: rgba(0, 0, 0, 0.87);
}
.mdc-chip:hover::before {
  opacity: 0.04;
}
.mdc-chip:not(.mdc-ripple-upgraded):focus::before, .mdc-chip.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-chip:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-chip:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-chip.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}
.mdc-chip:hover {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-chip.mdc-chip--selected .mdc-chip__checkmark,
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  margin: -4px 4px -4px -4px;
}
.mdc-chip:hover {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-chip__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-chip__icon--trailing:hover {
  color: rgba(0, 0, 0, 0.62);
}
.mdc-chip__icon--trailing:focus {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.mdc-chip__icon.mdc-chip__icon--trailing {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.mdc-chip__icon--trailing {
  margin: 0 -4px 0 4px;
}

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0;
}

.mdc-chip__text {
  white-space: nowrap;
}

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
}

.mdc-chip__checkmark {
  height: 20px;
}

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before {
  opacity: 0.08;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover::before {
  opacity: 0.12;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.2;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #0A51DA;
  /* @alternate */
  color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(10, 81, 218, 0.54);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
  color: #0A51DA;
  /* @alternate */
  color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #0A51DA;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #0A51DA);
}
.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px;
}

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
  transition: opacity 75ms linear;
  transition-delay: 80ms;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
  transition: width 0ms;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 0;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 20px;
}

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.mdc-chip-set .mdc-chip {
  margin: 4px;
}

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1);
}

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}
.mdc-layout-grid__cell--order-2 {
  order: 2;
}
.mdc-layout-grid__cell--order-3 {
  order: 3;
}
.mdc-layout-grid__cell--order-4 {
  order: 4;
}
.mdc-layout-grid__cell--order-5 {
  order: 5;
}
.mdc-layout-grid__cell--order-6 {
  order: 6;
}
.mdc-layout-grid__cell--order-7 {
  order: 7;
}
.mdc-layout-grid__cell--order-8 {
  order: 8;
}
.mdc-layout-grid__cell--order-9 {
  order: 9;
}
.mdc-layout-grid__cell--order-10 {
  order: 10;
}
.mdc-layout-grid__cell--order-11 {
  order: 11;
}
.mdc-layout-grid__cell--order-12 {
  order: 12;
}
.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}
.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc(var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2);
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc(var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2);
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc(var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2);
  }
}

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

.mdc-card {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.mdc-card--outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #e0e0e0;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-card__primary-action::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-card__primary-action.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  background-color: #000;
}
.mdc-card__primary-action:hover::before {
  opacity: 0.04;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px;
}
[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-card__action--button:last-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  text-align: left;
}
[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  text-align: right;
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity;
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer;
}

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-text-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  will-change: opacity, transform, color;
}
.mdc-text-field::before, .mdc-text-field::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-text-field.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
.mdc-text-field.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field::before, .mdc-text-field::after {
  background-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field:hover::before {
  opacity: 0.04;
}
.mdc-text-field:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-text-field::before, .mdc-text-field::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #0A51DA;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-line-ripple {
  background-color: #0A51DA;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  bottom: 20px;
}
[dir=rtl] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-floating-label {
  max-width: calc(100% - 48px);
}
.mdc-text-field .mdc-floating-label--float-above {
  transform: translateY(-50%) translateX(0%) scale(0.75);
}
[dir=rtl] .mdc-text-field .mdc-floating-label--float-above, .mdc-text-field .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-50%) translateX(0%) scale(0.75);
}
.mdc-text-field .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-box 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-box {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
  }
}
.mdc-text-field .mdc-floating-label {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
  pointer-events: none;
}
[dir=rtl] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}

.mdc-text-field__input {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 12px 6px;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  appearance: none;
}
.mdc-text-field__input::placeholder {
  transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  transform: translateY(-50%) scale(0.75);
  cursor: auto;
}

.mdc-text-field--outlined {
  border: none;
  overflow: visible;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.24);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.24);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: #0A51DA;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(0%) scale(0.75);
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(0%) scale(0.75);
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
.mdc-text-field--outlined .mdc-notched-outline {
  border-radius: 4px;
}
.mdc-text-field--outlined .mdc-notched-outline__idle {
  border-radius: 4px;
}
.mdc-text-field--outlined::before, .mdc-text-field--outlined::after {
  content: none;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  padding: 12px 16px 14px;
  border: none !important;
  background-color: transparent;
  z-index: 1;
}
.mdc-text-field--outlined .mdc-text-field__icon {
  z-index: 2;
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  width: auto;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__path {
  stroke-width: 2px;
}

.mdc-text-field--outlined.mdc-text-field--disabled {
  background-color: transparent;
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom: none;
}

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-110%) translateX(0%) scale(0.923);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-110%) translateX(0%) scale(0.923);
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 7px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  bottom: 16px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
  top: 12px;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-110%) translateX(-21px) scale(0.923);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-110%) translateX(21px) scale(0.923);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1;
}

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 48px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 12px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8);
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 38px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 38px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 38px;
}

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 38px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 12px;
}

.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-70%) translateX(0%) scale(0.923);
}
[dir=rtl] .mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-70%) translateX(0%) scale(0.923);
}
.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1;
}
.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0;
}
.mdc-text-field--dense .mdc-floating-label {
  font-size: 0.813rem;
}

.mdc-text-field__input:required + .mdc-floating-label::after {
  margin-left: 1px;
  content: "*";
}

.mdc-text-field--textarea {
  border-radius: 4px;
  display: flex;
  width: auto;
  height: auto;
  transition: none;
  border: 1px solid;
  overflow: hidden;
}
.mdc-text-field--textarea .mdc-floating-label {
  border-radius: 4px 4px 0 0;
}
.mdc-text-field--textarea .mdc-text-field__input {
  border-radius: 2px;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  border-color: rgba(0, 0, 0, 0.73);
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
  border-color: rgba(0, 0, 0, 0.73);
}
.mdc-text-field--textarea .mdc-floating-label--float-above {
  transform: translateY(-50%) translateX(0%) scale(0.923);
}
[dir=rtl] .mdc-text-field--textarea .mdc-floating-label--float-above, .mdc-text-field--textarea .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-50%) translateX(0%) scale(0.923);
}
.mdc-text-field--textarea .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea 250ms 1;
}
.mdc-text-field--textarea::before, .mdc-text-field--textarea::after {
  content: none;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--textarea .mdc-text-field__input {
  align-self: auto;
  box-sizing: content-box;
  height: auto;
  margin: 0;
  padding: 16px;
  padding-top: 32px;
  border: 1px solid transparent;
}
.mdc-text-field--textarea .mdc-floating-label {
  background-color: white;
  /* @noflip */
  left: 1px;
  /* @noflip */
  right: 0;
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  top: 18px;
  bottom: auto;
  width: auto;
  margin-top: 2px;
  padding: 12px 8px 8px 8px;
  line-height: 1.15;
  pointer-events: none;
}
[dir=rtl] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: 1px;
}
[dir=rtl] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

.mdc-text-field--fullwidth {
  width: 100%;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  display: block;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::before, .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::after {
  content: none;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding: 0;
}

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #b00020;
}

.mdc-text-field--dense + .mdc-text-field-helper-text {
  margin-bottom: 4px;
}
.mdc-text-field + .mdc-text-field-helper-text {
  margin-right: 12px;
  margin-left: 12px;
}
.mdc-text-field--outlined + .mdc-text-field-helper-text {
  margin-right: 16px;
  margin-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(10, 81, 218, 0.87);
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(10, 81, 218, 0.87);
}
.mdc-text-field--focused .mdc-text-field__input:required + .mdc-floating-label::after {
  color: #b00020;
}
.mdc-text-field--focused + .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) {
  border-color: #0A51DA;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
  border-color: #0A51DA;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0A51DA);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: #b00020;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
}
.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #b00020;
}
.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
  border-color: #b00020;
}
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
  border-color: #b00020;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
  border-color: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: #b00020;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: #b00020;
}

.mdc-text-field--disabled {
  background-color: #fafafa;
  border-bottom: none;
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-text-field__input::placeholder {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled + .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-text-field__icon {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled:not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}

.mdc-text-field--textarea.mdc-text-field--disabled {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: rgb(249, 249, 249);
  border-bottom-width: 1px;
  border-style: solid;
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input:focus {
  border-color: rgba(0, 0, 0, 0.26);
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
  border: 1px solid transparent;
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-floating-label {
  background-color: rgb(249, 249, 249);
}

@keyframes mdc-floating-label-shake-float-above-text-field-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923);
  }
}
@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923);
  }
}
.mdc-list {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  margin: 0;
  padding: 8px 0;
  /* @alternate */
  line-height: 1.5rem;
  list-style-type: none;
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__graphic {
  background-color: transparent;
}

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden;
}
.mdc-list-item:focus {
  outline: none;
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #0A51DA;
  /* @alternate */
  color: var(--mdc-theme-primary, #0A51DA);
}
.mdc-list-item--selected .mdc-list-item__graphic,
.mdc-list-item--activated .mdc-list-item__graphic {
  color: #0A51DA;
  /* @alternate */
  color: var(--mdc-theme-primary, #0A51DA);
}

.mdc-list-item--disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
}
.mdc-list-item[dir=rtl] .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
.mdc-list-item[dir=rtl] .mdc-list-item__meta, [dir=rtl] .mdc-list-item .mdc-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
  display: block;
}
.mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  display: block;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__secondary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-size: inherit;
}
.mdc-list--dense .mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list--dense .mdc-list-item {
  height: 40px;
}

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.mdc-list-item[dir=rtl] .mdc-list--dense .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 36px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list--avatar-list .mdc-list-item {
  height: 56px;
}

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  font-size: 40px;
  border-radius: 50%;
}
.mdc-list-item[dir=rtl] .mdc-list--avatar-list .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start;
}

.mdc-list--two-line .mdc-list-item {
  height: 72px;
}

.mdc-list--two-line.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px;
  font-size: 36px;
}
.mdc-list-item[dir=rtl] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 20px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
  animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: 150ms mdc-ripple-fg-opacity-out;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  background-color: #000;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
  opacity: 0.04;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
  opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
  opacity: 0.16;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.28;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.28;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
  opacity: 0.08;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
  background-color: #0A51DA;
}
@supports not (-ms-ime-align: auto) {
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0A51DA);
  }
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
  opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.2;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider--padded {
  margin: 0 16px;
}

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
.mdc-list-group[dir=rtl] .mdc-list-divider--inset, [dir=rtl] .mdc-list-group .mdc-list-divider--inset {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px);
}

.mdc-list-group .mdc-list {
  padding: 0;
}

.mdc-list-group__subheader {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px;
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-snackbar {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-right: 24px;
  padding-left: 24px;
  transform: translate(-50%, 100%);
  transition: transform 0.25s 0ms cubic-bezier(0.4, 0, 1, 1);
  background-color: #323232;
  pointer-events: none;
  will-change: transform;
}
@media (max-width: 599px) {
  .mdc-snackbar {
    left: 0;
    width: 100%;
    transform: translate(0, 100%);
  }
}
@media (min-width: 600px) {
  .mdc-snackbar {
    min-width: 288px;
    max-width: 568px;
    border-radius: 2px;
  }
}

@media (min-width: 600px) {
  .mdc-snackbar--align-start {
    /* @noflip */
    left: 24px;
    /* @noflip */
    right: initial;
    bottom: 24px;
    transform: translate(0, 200%);
  }
  [dir=rtl] .mdc-snackbar--align-start, .mdc-snackbar--align-start[dir=rtl] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 24px;
  }
}
@media (max-width: 599px) {
  .mdc-snackbar--align-start {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translate(0, 100%);
  }
}

.mdc-snackbar--active {
  transform: translate(0);
  transition: transform 0.25s 0ms cubic-bezier(0, 0, 0.2, 1);
  pointer-events: auto;
}
.mdc-snackbar--active:not(.mdc-snackbar--align-start) {
  transform: translate(-50%, 0);
}
@media (max-width: 599px) {
  .mdc-snackbar--active:not(.mdc-snackbar--align-start) {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translate(0);
  }
}

.mdc-snackbar__action-wrapper {
  /* @noflip */
  padding-left: 24px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-snackbar__action-wrapper, .mdc-snackbar__action-wrapper[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 24px;
}

.mdc-snackbar--action-on-bottom {
  flex-direction: column;
}

.mdc-snackbar__text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
  display: flex;
  align-items: center;
  height: 48px;
  transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
  color: white;
}
.mdc-snackbar[dir=rtl] .mdc-snackbar__text, [dir=rtl] .mdc-snackbar .mdc-snackbar__text {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}

@media (min-width: 600px) {
  .mdc-snackbar__text {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 24px;
  }
  [dir=rtl] .mdc-snackbar__text, .mdc-snackbar__text[dir=rtl] {
    /* @noflip */
    padding-left: 24px;
    /* @noflip */
    padding-right: 0;
  }
}

.mdc-snackbar--action-on-bottom .mdc-snackbar__text {
  margin-right: inherit;
}

.mdc-snackbar--action-on-bottom .mdc-snackbar__action-wrapper {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -12px;
  margin-bottom: 8px;
}
[dir=rtl] .mdc-snackbar--action-on-bottom .mdc-snackbar__action-wrapper, .mdc-snackbar--action-on-bottom .mdc-snackbar__action-wrapper[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-snackbar--multiline .mdc-snackbar__text {
  height: 80px;
}

.mdc-snackbar__action-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  color: #018786;
  /* @alternate */
  color: var(--mdc-theme-secondary, #018786);
  padding: 0;
  transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
  border: none;
  outline: none;
  background-color: transparent;
  opacity: 0;
  user-select: none;
  -webkit-appearance: none;
  visibility: hidden;
}
.mdc-snackbar__action-button::-moz-focus-inner {
  border: 0;
}
.mdc-snackbar__action-button:hover {
  cursor: pointer;
}
.mdc-snackbar__action-button:not([aria-hidden]) {
  visibility: inherit;
}

.mdc-snackbar--active .mdc-snackbar__text,
.mdc-snackbar--active .mdc-snackbar__action-button:not([aria-hidden]) {
  transition: opacity 0.3s 0ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 1;
}

.mdc-snackbar--multiline.mdc-snackbar--action-on-bottom .mdc-snackbar__text {
  margin: 0;
}

.hidden {
  display: none;
}

.TopAppBarRippleSpan {
  font-size: 0.8em;
  --mdc-ripple-fg-size: 28.8px !important;
  --mdc-ripple-fg-scale: 1.66667 !important;
  --mdc-ripple-left: 5px !important;
  --mdc-ripple-top: 10px !important;
  --mdc-ripple-fg-translate-start: 0px, 0px !important;
  --mdc-ripple-fg-translate-end: 0px, 0px !important;
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 180px !important;
}

.TopAppBar {
  top: 0 !important;
}

.show-message {
  text-align: center;
  color: #0A51DA;
}

.NoResultsFound {
  padding: 30px;
}

.header-div {
  padding-top: 64px;
}

.LayoutTiny {
  height: 100%;
}

.Navigation {
  padding: 10px;
}

.Loading {
  position: fixed; /* or absolute */
  top: 45%;
  left: 45%;
  z-index: 9999;
}

.Login {
  height: 100%;
  background-color: #f7f8ff;
}
.Login .disable {
  opacity: 0.4;
}
.Login .Login-title {
  padding-top: 50px;
  text-align: center;
  color: #0A51DA;
}
.Login .Login-title span {
  font-size: 36px;
  font-weight: 900;
  border-bottom: 3px solid #0A51DA;
}
.Login .Login-grid {
  width: 100%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 35%;
}
.Login .Login-grid .Login-cell {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
}
.Login .Login-grid .Login-cell .Login-cell-title {
  height: 60px;
}
.Login .Login-grid .Login-cell .Login-logo {
  min-height: 100px;
  vertical-align: middle;
}
.Login .Login-grid .Login-cell .Login-logo .Login-logo-icon {
  width: 100px;
}
.Login .Login-grid .Login-cell .Login-logo .Login-logo-icon-oa {
  vertical-align: middle;
  width: 190px;
  margin-top: 25px;
}
.Login .Login-grid .Login-cell .Login-btn {
  width: 100px;
  background-color: #0A51DA;
  color: white;
  border-radius: 18px;
  box-shadow: 5px 10px 10px rgba(10, 81, 218, 0.2);
}
.Login .Login-grid .Login-cell .Login-btn:hover {
  box-shadow: 5px 10px 10px rgba(10, 81, 218, 0.9);
}
.Login .Login-grid .Login-cell:hover {
  padding: 20px;
  box-shadow: 5px 10px 10px rgba(10, 81, 218, 0.2);
}
@media (max-width: 400px) {
  .Login .Login-grid {
    top: 20%;
  }
}
.Login .Login-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #0A51DA;
  color: white;
  text-align: center;
  cursor: pointer;
}
.Login .Login-footer .Git-logo-icon {
  height: 36px;
  border-top: 12px;
}
.Login .Login-footer .Login-footer-msg {
  line-height: 36px;
  color: white;
}
.Login .Login-footer:hover {
  background-color: black;
}
.Login .Login-greeting {
  position: fixed;
  left: 20px;
  bottom: 40px;
  width: 100%;
  font-weight: 900;
  font-family: "华文细黑", "Microsoft YaHei", "微软雅黑", serif;
}

.GroupList {
  margin-left: 15px;
}

.GroupList-item {
  border-bottom: 1px solid;
}

.GroupList-item .mdc-list-item__secondary-text {
  margin-top: 5px;
}

.GroupList-group-item {
  float: left;
  width: 95%;
}

.DeleteGroup-div {
  display: inline;
  float: right;
  text-align: right;
  width: auto;
  cursor: pointer;
}

.GroupDetail-grid {
  width: 100%;
}

.GroupDetail-inner {
  width: 100%;
}

.PersonDelete {
  cursor: pointer;
}

.GroupDetail-jon-link {
  width: 550px;
  color: cornflowerblue;
  overflow: auto;
}

.AddGroup-Modal {
  margin: 100px 25% auto;
  padding: 20px;
}

.TargetList {
  margin-left: 15px;
}

.TargetList-item {
  border-bottom: 1px solid;
}

.TargetList-item .mdc-list-item__secondary-text {
  margin-top: 5px;
}

.TargetList-group-item {
  float: left;
  width: 95%;
}

.DeleteTarget-div {
  display: inline;
  float: right;
  text-align: right;
  width: auto;
  cursor: pointer;
}

.AddAccess-Select {
  width: 100%;
  margin: 20px;
}

