$mdc-theme-primary: #0A51DA;

@import '@material/theme/mdc-theme';
@import '@material/react-card/index';
@import '@material/react-button/index';
@import '@material/react-material-icon/index';
@import '@material/react-ripple/index';
@import '@material/react-select/index';
@import '@material/react-top-app-bar/index';
@import '@material/react-material-icon/index';
@import '@material/react-text-field/index';
@import '@material/react-tab-bar/index';
@import '@material/react-tab-scroller/index';
@import '@material/elevation/mdc-elevation';
@import '@material/react-layout-grid/index';
@import "@material/button/mdc-button";
@import "@material/chips/mdc-chips";
@import "@material/layout-grid/mdc-layout-grid";
@import '@material/react-card/index';
@import "@material/textfield/mdc-text-field";
@import '@material/react-list/index';
@import "@material/snackbar/mdc-snackbar";

@import 'components/foundation/Header.scss';
@import 'components/foundation/Helper.scss';
@import 'components/foundation/Layout.scss';
@import 'components/foundation/LayoutTiny.scss';
@import 'components/foundation/Navigation.scss';
@import 'components/foundation/Loading.scss';
@import 'components/auth/Login.scss';
@import 'components/auth/Ticket.scss';
@import 'components/permission/GroupList.scss';
@import "components/permission/GroupDetail.scss";
@import "components/permission/AddGroupModal.scss";
@import "components/permission/TargetList.scss";
@import "components/permission/AddAccessModal.scss";
